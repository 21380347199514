/* import __COLOCATED_TEMPLATE__ from './product-ia-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked, TrackedObject } from 'tracked-built-ins';

interface OptOutFeedback {
  somethingMissing: boolean;
  findingItHard: boolean;
  somethingBroken: boolean;
  dontLikeDesign: boolean;
  other: boolean;
  moreDetails: string;
}

type MODEL_TYPE = 'opt-in' | 'opt-out';

interface Signature {
  Args: {
    displayModal: boolean;
    onModalClose: () => void;
    onOptIn: () => void;
    onOptOut: (feedback: OptOutFeedback) => void;
    modalType: MODEL_TYPE;
  };
}

export default class ProductIaModal extends Component<Signature> {
  @tracked optOutFeedback: OptOutFeedback = new TrackedObject({
    somethingMissing: false,
    findingItHard: false,
    somethingBroken: false,
    dontLikeDesign: false,
    other: false,
    moreDetails: '',
  });

  get isPrimaryBtnDisabled(): boolean {
    if (this.args.modalType === 'opt-in') {
      return false;
    }
    if (this.args.modalType === 'opt-out') {
      return !Object.keys(this.optOutFeedback).some(
        (key: string) => this.optOutFeedback[key as keyof OptOutFeedback],
      );
    }
    return true;
  }

  @action
  onPrimaryBtnClick() {
    if (this.args.modalType === 'opt-in') {
      this.args.onOptIn();
    } else {
      this.args.onOptOut(this.optOutFeedback);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ProductIaModal': typeof ProductIaModal;
    'inbox2/user-menu/product-ia-modal': typeof ProductIaModal;
  }
}
