/* import __COLOCATED_TEMPLATE__ from './workspace-command-k.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import { ConversationsViewType } from 'embercom/services/inbox-state';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { type ValidLocales } from './user-menu/locale-switcher';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type ExperimentsApi from 'embercom/services/experiments-api';
import { type CommandKMetadata } from 'embercom/services/command-k';
import { type JumpToValue } from 'embercom/objects/inbox/command-k/jump-to';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type Theme from 'embercom/objects/inbox/types/theme';

export default class WorkspaceCommandK extends Component {
  @service declare router: Router;
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare experimentsApi: ExperimentsApi;
  @service declare intercomCallService: IntercomCallService;

  get isOnSearchRoute() {
    return (
      this.router.currentRouteName?.startsWith('inbox.workspace.inbox.search') ||
      this.router.currentRouteName?.startsWith('inbox.workspace.search')
    );
  }

  get shouldDisplayWorkspaceActions() {
    return (
      !this.inboxState.hasSelectedConversations &&
      !this.router.currentRouteName?.startsWith('inbox.workspace.inbox.linked-conversations')
    );
  }

  trackingDataFromSelectValue(locale: string) {
    return {
      language: locale,
    };
  }

  get currentAdminCanManageTags() {
    return this.session.teammate.permissions.canManageTags;
  }

  get canSeeNewSettings() {
    return this.session.workspace.isFeatureEnabled('team-activation-channels-new-ia-settings-hub');
  }

  @action manageTags() {
    let url: string = this.router.urlFor('apps.app.settings.data.tags', this.session.workspace);
    safeWindowOpen(url);
  }

  @action manageMacros() {
    let manageMacrosRoute = 'apps.app.settings.helpdesk.macros';
    let url: string = this.router.urlFor(manageMacrosRoute, this.session.workspace);
    safeWindowOpen(url);
  }

  @action manageWorkflowConnectorActions() {
    let actionsRoute = 'apps.app.settings.app-settings.custom-actions';
    let url: string = this.router.urlFor(actionsRoute, this.session.workspace);
    safeWindowOpen(url);
  }

  @action goToAppStore() {
    this.router.transitionTo('apps.app.appstore.index', this.session.workspace.id);
  }

  @action jumpToInbox(inbox: any) {
    if (inbox.category === InboxCategory.Dashboard) {
      this.router.transitionTo('inbox.workspace.dashboard');
    } else {
      this.router.transitionTo('inbox.workspace.inbox.inbox', inbox.category, inbox.id);
    }
  }

  @action commandKGoToTrackingDataFromSelectValue(goto: JumpToValue) {
    let inboxType;

    switch (goto.category) {
      case InboxCategory.Shared:
        inboxType = goto.id;
        break;
      case InboxCategory.Admin:
        if (goto.id.toString() === this.session.teammate.id.toString()) {
          inboxType = 'me';
        } else {
          inboxType = goto.category;
        }
        break;
      default:
        inboxType = goto.category;
        break;
    }

    return {
      selected_inbox: inboxType,
      selected_inbox_id: goto.id,
      view_type: goto.category === InboxCategory.View ? goto.viewSummary.systemType : undefined,
      has_dynamic_admin_assignee_predicates:
        goto.category === InboxCategory.View
          ? goto.viewSummary.hasDynamicAdminAssigneePredicates
          : undefined,
    };
  }

  @action
  goToNewConversation() {
    this.inboxState.goBackToInboxList();
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
      queryParams: {
        ticketTypeId: undefined,
      },
    });
  }

  @action
  startOutboundCall() {
    this.inboxState.goBackToInboxList();
    this.intercomCallService.openDialler({
      diallerContext: 'outbound',
      analyticsConfig: {
        place: 'cmd_k',
      },
    });
  }

  @action async changeLanguage(value: ValidLocales) {
    this.session.changeLanguage(value);
  }

  @action switchToTableView(_: any, __: any, metadata: CommandKMetadata) {
    this.inboxState.switchConversationsView(ConversationsViewType.TableFullscreen, {
      keyboardShortcutUsed: metadata.shortcutUsed,
      section: metadata.shortcutUsed ? undefined : 'command_k',
    });
  }

  @action transitionToSearch() {
    this.router.transitionTo('inbox.workspace.inbox.search');
  }

  @action setTheme(theme: 'dark' | 'light' | 'system') {
    this.session.setTheme(theme as Theme);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::WorkspaceCommandK': typeof WorkspaceCommandK;
    'inbox2/workspace-command-k': typeof WorkspaceCommandK;
  }
}
