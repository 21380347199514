/* import __COLOCATED_TEMPLATE__ from './phone-dialler-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { taskFor } from 'ember-concurrency-ts';
import { type NewConversationWireFormat } from 'embercom/lib/inbox2/types';
import PhoneCountryCodes from 'embercom/lib/phone-codes';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import type InboxState from 'embercom/services/inbox-state';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';

interface Args {
  phoneNumber?: string;
  countryCode?: string;
}

export default class PhoneDiallerModal extends Component<Args> {
  @service declare twilioService: TwilioService;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomCallService: IntercomCallService;
  @service declare inboxState: InboxState;
  @service declare router: RouterService;
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare inboxApi: InboxApi;

  @tracked toPhoneNumber = this.args.phoneNumber || '';
  @tracked phoneNumbers: any[] = [];
  @tracked phoneNumberGroupList?: any;
  @tracked selectedPhoneNumber?: string;
  @tracked selectedCountry?: string;
  @tracked countryCode = '';
  @tracked selectedMode = 'keypad';
  @tracked isActivelyCalling = false;
  @tracked isActivelyCallingId?: string | number;
  @tracked listOfTransferOptions: any;

  phoneNumbersMap: { [key: string]: string } = {};

  get isOutboundMode() {
    return this.intercomCallService.diallerContext === 'outbound';
  }

  get isConferenceMode() {
    return this.intercomCallService.diallerContext === 'conference';
  }

  get isCallTransferMode() {
    return this.intercomCallService.diallerContext === 'transfer';
  }

  get callButtonIcon(): InterfaceIconName {
    switch (this.intercomCallService.diallerContext) {
      case 'conference':
        return 'add-participant';
      case 'transfer':
        return 'phone-transfer';
      default:
        return 'phone';
    }
  }

  get callButtonLabel() {
    switch (this.intercomCallService.diallerContext) {
      case 'conference':
        return this.isActivelyCalling
          ? 'calling.dialler-modal.adding-to-call-button-label'
          : 'calling.dialler-modal.add-people-label';
      case 'transfer':
        return this.isActivelyCalling
          ? 'calling.dialler-modal.transferring-call-button-label'
          : 'calling.dialler-modal.transfer-call-button-label';
      default:
        return this.isActivelyCalling
          ? 'calling.dialler-modal.calling-button-label'
          : 'calling.dialler-modal.call-button-label';
    }
  }

  get showAdminAndTeamsListsForTransfer() {
    if (this.isCallTransferMode) {
      if (!this.twilioService.incomingCall) {
        return false;
      }
    }

    return true;
  }

  get showAdminList() {
    if (this.isOutboundMode) {
      return false;
    }

    if (!this.showAdminAndTeamsListsForTransfer) {
      return false;
    }

    return true;
  }

  get showTeamsList() {
    if (!this.isCallTransferMode) {
      return false;
    }

    return this.showAdminAndTeamsListsForTransfer;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.isOutboundMode) {
      this.selectedMode = 'user_search';
    }

    this.phoneNumberChanged();

    if (this.args.countryCode) {
      this.setPhoneNumberPrefix(this.args.countryCode);
    }

    this.getWorkspacePhoneNumbers();
  }

  async getWorkspacePhoneNumbers() {
    if (!this.intercomCallService.isWorkspacePhoneNumbersLoaded) {
      await this.intercomCallService.getWorkspacePhoneNumbers();
    }
    this.phoneNumbers = this.intercomCallService.workspacePhoneNumbers.map(
      (phoneNumber: PhoneNumber) => ({
        text: phoneNumber.name,
        value: phoneNumber.phoneNumber,
        component: 'inbox2/phone-number-option',
        componentAttrs: {
          name: phoneNumber.name,
          phoneNumber: phoneNumber.phoneNumber,
        },
      }),
    );
    this.phoneNumberGroupList = [{ items: this.phoneNumbers }];
    this.selectedPhoneNumber = this.phoneNumbers[0]?.value;
    if (
      !this.toPhoneNumber &&
      !this.selectedCountry &&
      this.intercomCallService.workspacePhoneNumbers.length > 0
    ) {
      this.selectedCountry =
        this.intercomCallService.workspacePhoneNumbers[0].countryIsoCode.toLowerCase();
      this.setPhoneNumberPrefix(this.selectedCountry);
    }
    this.phoneNumbers.forEach((phoneNumber) => {
      this.phoneNumbersMap[phoneNumber.value] = phoneNumber.text;
    });
  }

  get countries() {
    return Object.values(PhoneCountryCodes).map((phoneCodeObject: any) => ({
      text: this.intl.t(phoneCodeObject.nameTranslationKey),
      value: phoneCodeObject.code,
      component: 'inbox2/country-code-option',
      componentAttrs: {
        name: this.intl.t(phoneCodeObject.nameTranslationKey),
        code: phoneCodeObject.dial_code,
        flagUrl: phoneCodeObject.asset_url,
      },
    }));
  }

  @action
  setPhoneNumberPrefix(country: string) {
    this.selectedCountry = country;
    this.toPhoneNumber = this.toPhoneNumber.replace(this.countryCode, '').replace(' ', '');
    this.countryCode = (PhoneCountryCodes as any)[this.selectedCountry].dial_code;
    this.toPhoneNumber = `${this.countryCode} ${this.toPhoneNumber}`;
  }

  get selectedCountryFlagAssetUrl() {
    return assetUrl(`/assets/svgs/flags/${this.selectedCountry}.svg`);
  }

  async createConversation(contactId: string) {
    let user = await this.inboxSearchSuggestionsService.loadUserFromId(contactId);
    if (!user) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.find-or-create-contact-error'),
      );
      return;
    }

    if (!this.session.isTeammateLoaded) {
      this.session.getTeammate(this.session.workspace.id);
    }

    let data: NewConversationWireFormat = {
      app_id: this.session.workspace.id,
      admin_assignee_id: this.session.teammate.id,
      team_assignee_id: TeamSummary.unassigned.id,
      sender_id: this.session.teammate.id,
      blocks: [],
      type: objectTypes.call,
      user_ids: [user.id],
    };

    return await this.inboxState.createNewConversation(data, [], false);
  }

  setCallingState(id?: string | number) {
    this.isActivelyCalling = true;
    this.isActivelyCallingId = id;
  }

  resetCallingState() {
    this.intercomCallService.nextCallIsTestCall = false;
    this.isActivelyCalling = false;
    this.isActivelyCallingId = undefined;
  }

  @action
  async callUser(user: UserSummary) {
    this.setCallingState(user.id);

    let phoneNumber = user.phone;
    if (!phoneNumber) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.validate-phone-number-error'),
      );
      this.resetCallingState();
      return;
    }
    await this.callNumber(phoneNumber);

    this.resetCallingState();
  }

  @action
  async dialNumber() {
    this.setCallingState();

    let validatedPhoneNumber = await this.intercomCallService.getValidatedPhoneNumber(
      this.toPhoneNumber,
      this.selectedCountry!,
    );
    if (!validatedPhoneNumber) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.validate-phone-number-error'),
      );
      this.resetCallingState();
      return;
    }

    this.intercomCallService.saveLastDialledCountry(this.selectedCountry);
    await this.callNumber(validatedPhoneNumber);
    this.resetCallingState();
  }

  async callNumber(phoneNumber: string) {
    if (!this.isOutboundMode) {
      this.handleExternalCall(phoneNumber);
      return;
    }

    let contact = await this.intercomCallService.findOrCreateContact(phoneNumber);

    let preferredConversationId = await this.intercomCallService.contactsActiveCallConversation(
      contact.id,
    );
    if (preferredConversationId) {
      this.router.transitionTo(
        'inbox.workspace.inbox.conversation.conversation',
        preferredConversationId,
      );
      this.intercomCallService.closeDialler();
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.contact-already-engaged'),
      );
      return;
    }

    let conversation = await this.createConversation(contact.id);
    if (!conversation) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.create-conversation-error'),
      );
      return;
    }

    this.router.transitionTo('inbox.workspace.inbox.conversation.conversation', conversation.id);
    let conversationObj = await this.inboxApi.fetchConversation(conversation.id);
    await this.twilioService.callNumber(
      phoneNumber,
      conversationObj,
      contact,
      this.selectedPhoneNumber,
    );
    this.intercomCallService.closeDialler();
    let callPlacedInMode = this.selectedMode === 'keypad' ? 'calling' : 'user_search';
    this.intercomCallService.registerCallEvent({
      action: 'called',
      object: 'dialler_modal',
      place: callPlacedInMode,
    });
  }

  @action
  close() {
    this.intercomCallService.closeDialler();
  }

  get selectedPhoneNumberText() {
    if (!this.selectedPhoneNumber) {
      return '';
    }

    return this.phoneNumbersMap[this.selectedPhoneNumber] || this.selectedPhoneNumber;
  }

  @action
  inputDigit(keyCode: string) {
    if (keyCode === '+') {
      this.toPhoneNumber = this.toPhoneNumber.slice(0, -2);
    }
    this.toPhoneNumber += keyCode;
    this.phoneNumberChanged();
  }

  @action
  phoneNumberChanged(event?: Event) {
    let phoneNumber = (event?.target as HTMLInputElement)?.value || this.toPhoneNumber;
    Object.values(PhoneCountryCodes).forEach((phoneCodeObject: any) => {
      if (phoneNumber.startsWith(phoneCodeObject.dial_code)) {
        this.selectedCountry = phoneCodeObject.code;
        this.countryCode = phoneCodeObject.dial_code;
      }
    });
  }

  @action
  setSelectedPhoneNumber(value: string) {
    if (!this.toPhoneNumber || this.toPhoneNumber.trim() === this.countryCode) {
      this.selectedCountry = this.intercomCallService.workspacePhoneNumbers
        .findBy('phoneNumber', value)
        ?.countryIsoCode.toLowerCase();
      this.setPhoneNumberPrefix(this.selectedCountry!);
    }
    this.selectedPhoneNumber = value;
  }

  @action switchMode(mode: string) {
    this.selectedMode = mode;
    this.intercomCallService.registerCallEvent({
      action: 'ui_mode_switched',
      object: 'dialler_modal',
      place: 'dialler_modal',
      context: mode,
    });
  }

  @action async handleCallToAdmin(admin: AdminSummary) {
    this.setCallingState(admin.id);

    if (this.isConferenceMode) {
      await taskFor(this.twilioService.addTeammateToCall).perform(admin);
    } else if (this.isCallTransferMode) {
      taskFor(this.twilioService.transferToAdmin).perform(admin);
    }

    this.close();
    this.resetCallingState();
  }

  @action handleCallToTeam(team: TeamSummary) {
    this.setCallingState(team.id);

    if (this.isCallTransferMode) {
      taskFor(this.twilioService.transferToTeam).perform(team.id);
    }

    this.close();
    this.resetCallingState();
  }

  @action async handleExternalCall(phoneNumber: string) {
    if (this.isConferenceMode) {
      await taskFor(this.twilioService.addExternalParticipantToCall).perform(phoneNumber);
    } else if (this.isCallTransferMode) {
      taskFor(this.twilioService.transferToExternalNumber).perform(phoneNumber);
    }
    this.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneDiallerModal': typeof PhoneDiallerModal;
    'inbox2/phone-dialler-modal': typeof PhoneDiallerModal;
  }
}
